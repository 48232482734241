import React from "react";
import Slide from "react-reveal";

const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Resume = ({ data }) => {
  if (!data) return null;

  const { skillmessage, education, work, skills } = data;

  const educationItems = education.map((educationItem) => (
    <div key={educationItem.school}>
      <h3>{educationItem.school}</h3>
      <p className="info">
        {educationItem.degree} <span>&bull;</span>
        <em className="date">{educationItem.graduated}</em>
      </p>
      <p>{educationItem.description}</p>

      {/* <ul>
      {educationItem.classes && educationItem.classes.map((classItem, index) => (
        <li key={index}>{classItem}</li>
      ))}
    </ul> */}
    </div>
  ));

  const workItems = work.map((workItem) => (
    <div key={workItem.company}>
      <h3>{workItem.company}</h3>
      <p className="info">
        {workItem.title}
        <span>&bull;</span> <em className="date">{workItem.years}</em>
      </p>
      <p>{workItem.description}</p>
    </div>
  ));

    
  const skillItems = skills.map((skill) => {
    const backgroundColor = getRandomColor();
    const className = `bar-expand ${skill.name.toLowerCase()}`;
    const width = "width: 80%";

    return (
      <li key={skill.name}>
        <em>{skill.name}</em>
      </li>
    );
  });

  return (
    <section id="resume">
      <Slide left duration={1300}>
        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{educationItems}</div>
            </div>
          </div>
        </div>
      </Slide>
      <Slide left duration={1300}>
        <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>
          <div className="nine columns main-col">{workItems}</div>
        </div>
      </Slide>
      <Slide left duration={1300}>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <p>{skillmessage}</p>
            <div>
              <ul className="skills">{skillItems}</ul>
            </div>
          </div>
        </div>
      </Slide>
    </section>
  );
};

export default Resume;

