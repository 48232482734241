import React from "react";
import Fade from "react-reveal/Fade";

const About = (props) => {
  if (!props.data) return null;

  const { name, image, bio, phone, email, resumedownload, social } = props.data;
  const {name:linkedIN, url } = social[0];
  const {name:github, url:giturl } = social[1];
  const { city, state, zip } = props.data.address;
  const profilepic = "images/" + image;

  return (
    <section id="about">
      <Fade duration={1000}>
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Isaac Callison Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>{bio}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name}</span>
                  <span>
                    <br />
                    {city}, {state}, {zip}
                  </span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span><a href={`mailto:${email}`}>{email}</a></span>
                  <br />
                  <span><a href={url}>{linkedIN}</a></span>
                  <br />
                  <span><a href={giturl}>{github}</a></span>
                </p>
              </div>
              <div className="columns download">
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default About;
