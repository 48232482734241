import React, {useState, useEffect} from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal/Fade";

const Header = ({data}) => {
    const [ani, setAni] = useState("square");
    const animationList = ["circle", "square","fountain", "lines"]
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * animationList.length);
        setAni(animationList[randomIndex]);
    }, [])


    if (!data) 
        return null;
    


    const {project, github, name, description} = data;


    return (
        <header id="home">
            <ParticlesBg type={ani}
                bg={true}/>

            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                    Show navigation
                </a>
                <a className="mobile-btn" href="#home" title="Hide navigation">
                    Hide navigation
                </a>

                <ul id="nav" className="nav">
                    <li className="current">
                        <a className="smoothscroll" href="#home">
                            Home
                        </a>
                    </li>

                    <li>
                        <a className="smoothscroll" href="#about">
                            About
                        </a>
                    </li>

                    <li>
                        <a className="smoothscroll" href="#resume">
                            Resume
                        </a>
                    </li>

                    {/* <li>
            <a className="smoothscroll" href="#portfolio">
              Works
            </a>
          </li> */}

                    <li>
                        <a className="smoothscroll" href="#contact">
                            Contact
                        </a>
                    </li>
                </ul>
            </nav>

            <div className="row banner">
                <div className="banner-text">
                    <Fade bottom>
                        <h1 className="responsive-headline">
                            {name}</h1>
                    </Fade>
                    <Fade bottom
                        duration={1200}>
                        <h3>{description}.</h3>
                    </Fade>
                    <hr/>
                    <Fade bottom
                        duration={2000}></Fade>
                </div>
            </div>

            <p className="scrolldown">
                <a className="smoothscroll" href="#about">
                    <i className="icon-down-circle"></i>
                </a>
            </p>
        </header>
    );
};

export default Header;
